export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#0C4DED"}],"link":[{"rel":"preconnect","href":"https://js-agent.newrelic.com","crossorigin":"anonymous"},{"rel":"dns-prefetch","href":"https://js-agent.newrelic.com"},{"rel":"preconnect","href":"https://brand.frontiersin.org","crossorigin":"anonymous"},{"rel":"dns-prefetch","href":"https://brand.frontiersin.org"},{"rel":"preconnect","href":"https://graphql.contentful.com","crossorigin":"anonymous"},{"rel":"dns-prefetch","href":"https://graphql.contentful.com"},{"rel":"preconnect","href":"https://images.ctfassets.net"}],"style":[],"script":[],"noscript":[],"title":"Frontiers | Science News","htmlAttrs":{"lang":"en"}}

export const appBuildAssetsDir = "science-news/_nuxt"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null