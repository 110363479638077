import { FrontiersImage } from "@frontiers-vue3/public-pages";

export default defineNuxtPlugin(async (nuxtApp: any) => {
  const components = {
    FrontiersImage
  };

  Object.entries(components).forEach(([name, component]) => {
    nuxtApp.vueApp.component(name, component);
  });
});
