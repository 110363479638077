<script lang="ts" setup>
const config = useRuntimeConfig();

const noscriptContentGtm = computed((): string => {
  return `<iframe
            src="${config.public.gtmServerUrl}/ns.html?id=${config.public.gtmId}&gtm_auth=${config.public.gtmAuth}&gtm_preview=${config.public.gtmPreview}&gtm_cookies_win=x"
            height="0"
            width="0"
            style="display: none; visibility: hidden">
          </iframe>`;
});

useHead({
  link: [
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: `${config.public.faviconSize16}`
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: `${config.public.faviconSize32}`
    },
    {
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "180x180",
      href: `${config.public.faviconSize180}`
    }
  ]
});

onMounted(() => {
  navigator.serviceWorker.register("/news/sw.js", {
    scope: `/news/`
  });
});
</script>

<template>
  <VitePwaManifest href="/news/manifest.json" />
  <NuxtLayout />
  <noscript v-html="noscriptContentGtm"></noscript>
</template>
<style lang="scss">
#__nuxt {
  position: relative;
}
</style>
