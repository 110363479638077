import { ILoggedUserInfo, UserService } from "@frontiers-vue3/public-pages";
import { IDataLayerUserInfo } from "~/types/tracking";

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

function pushDataLayerVariables(dataLayerItem: any): void {
  let dataLayer: any[];

  try {
    dataLayer = window.dataLayer;
  } catch (e) {
    throw new Error("window.dataLayer is undefined");
  }

  dataLayer.push(dataLayerItem);
}

async function setDataLayerUserInfo(): Promise<void> {
  const userService = new UserService();
  try {
    const loggedUserInfo: ILoggedUserInfo | undefined =
      await userService.getLoggedUserInfo();
    if (loggedUserInfo) {
      const loopData: IDataLayerUserInfo = {
        loopId: loggedUserInfo.id.toString()
      };
      pushDataLayerVariables(loopData);
    }
  } catch (err) {
    console.error(`Error getting from userInfo for datalayer - ${err}`);
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  window.dataLayer = window.dataLayer || [];
  const appIdDataLayer: object = {
    app_id: config.public.spaceId
  };

  nuxtApp.hook("page:finish", async () => {
    pushDataLayerVariables(appIdDataLayer);
    await setDataLayerUserInfo();
    // Script Google Tag Manager
    useHead({
      script: [
        {
          hid: "gtmHead",
          innerHTML: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true;
            j.src = '${config.public.gtmServerUrl}/gtm.js?id=' + i + dl + '&gtm_auth=${config.public.gtmAuth}&gtm_preview=${config.public.gtmPreview}&gtm_cookies_win=x';
            f.parentNode.insertBefore(j, f);
          })
          (window, document, 'script', 'dataLayer', '${config.public.gtmId}')`
        }
      ]
    });
  });
});
