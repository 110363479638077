import revive_payload_client_4sVQNw7RlN from "/usr/src/science-news/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/usr/src/science-news/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/usr/src/science-news/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/usr/src/science-news/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/science-news/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/science-news/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/science-news/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_85e54a0a_ILVv1ZwrMu from "/usr/src/science-news/.nuxt/templates.pwa.client.85e54a0a.ts";
import plugin_eTVJQYlCmx from "/usr/src/science-news/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/science-news/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import common_clib_Pj5GAC1sLE from "/usr/src/science-news/plugins/common-clib.ts";
import frontiers_clib_config_hHaxZHvMpg from "/usr/src/science-news/plugins/frontiers-clib-config.ts";
import gtm_tracking_client_I9u8Vy1cUj from "/usr/src/science-news/plugins/gtm.tracking.client.ts";
import navigation_client_1y6va50tFC from "/usr/src/science-news/plugins/navigation.client.ts";
import preview_6oGpsaen2C from "/usr/src/science-news/plugins/preview.ts";
import register_components_tOd2lOWm3D from "/usr/src/science-news/plugins/register-components.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_85e54a0a_ILVv1ZwrMu,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  common_clib_Pj5GAC1sLE,
  frontiers_clib_config_hHaxZHvMpg,
  gtm_tracking_client_I9u8Vy1cUj,
  navigation_client_1y6va50tFC,
  preview_6oGpsaen2C,
  register_components_tOd2lOWm3D
]