import { $frontiersClibConfig } from "@frontiers-vue3/public-pages";

export default defineNuxtPlugin(() => {
  const isDevelopmentMode = process.env.NODE_ENV === "development";
  const config = useRuntimeConfig();

  $frontiersClibConfig.setConfig({
    isFrontiers: config.public.domain.includes("frontiers"),
    tenantName: config.public.spaceName,
    mainDomain: config.public.domain,
    loopUrl: config.public.loopUrl,
    ssMainDomain: config.public.ssMainDomain,
    useWwwAbsoluteUrls: !isDevelopmentMode
  });
});
