import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAsidePosts, LazyAssetVideo, LazyAuthor, LazyBriefPost, LazyCardGridHome, LazyCategoryCloud, LazyFloatingShareButton, LazyFrontiersRichTextRenderer, LazyHomeMainHeader, LazyImageLink, LazyLayoutAside, LazyNewsSearchBar, LazyPostAside, LazyPostListPage, LazyReadingProgressIndicator, LazyRelatedSubjects } from '#components'
const lazyGlobalComponents = [
  ["AsidePosts", LazyAsidePosts],
["AssetVideo", LazyAssetVideo],
["Author", LazyAuthor],
["BriefPost", LazyBriefPost],
["CardGridHome", LazyCardGridHome],
["CategoryCloud", LazyCategoryCloud],
["FloatingShareButton", LazyFloatingShareButton],
["FrontiersRichTextRenderer", LazyFrontiersRichTextRenderer],
["HomeMainHeader", LazyHomeMainHeader],
["ImageLink", LazyImageLink],
["LayoutAside", LazyLayoutAside],
["NewsSearchBar", LazyNewsSearchBar],
["PostAside", LazyPostAside],
["PostListPage", LazyPostListPage],
["ReadingProgressIndicator", LazyReadingProgressIndicator],
["RelatedSubjects", LazyRelatedSubjects]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
