import { default as index4SwHJ3j6eqMeta } from "/usr/src/science-news/pages/index.vue?macro=true";
import { default as _91postSlug_93vMUSMV9PXYMeta } from "/usr/src/science-news/pages/news/[postSlug].vue?macro=true";
import { default as _91postSlug_93OywRrynNLwMeta } from "/usr/src/science-news/pages/news/[postYear]/[postMonth]/[postDay]/[postSlug].vue?macro=true";
import { default as indexmsvEq3m3QzMeta } from "/usr/src/science-news/pages/news/all/index.vue?macro=true";
import { default as _91author_93BwbisqKRTmMeta } from "/usr/src/science-news/pages/news/author/[author].vue?macro=true";
import { default as _91category_934YE3F1YCDwMeta } from "/usr/src/science-news/pages/news/category/[category].vue?macro=true";
import { default as indexwniufHFDZqMeta } from "/usr/src/science-news/pages/news/index.vue?macro=true";
import { default as _91tag_93wrvGBYunX5Meta } from "/usr/src/science-news/pages/news/tag/[tag].vue?macro=true";
export default [
  {
    name: index4SwHJ3j6eqMeta?.name ?? "index",
    path: index4SwHJ3j6eqMeta?.path ?? "/",
    meta: index4SwHJ3j6eqMeta || {},
    alias: index4SwHJ3j6eqMeta?.alias || [],
    redirect: index4SwHJ3j6eqMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91postSlug_93vMUSMV9PXYMeta?.name ?? "news-postSlug",
    path: _91postSlug_93vMUSMV9PXYMeta?.path ?? "/news/:postSlug()",
    meta: _91postSlug_93vMUSMV9PXYMeta || {},
    alias: _91postSlug_93vMUSMV9PXYMeta?.alias || [],
    redirect: _91postSlug_93vMUSMV9PXYMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/[postSlug].vue").then(m => m.default || m)
  },
  {
    name: _91postSlug_93OywRrynNLwMeta?.name ?? "news-postYear-postMonth-postDay-postSlug",
    path: _91postSlug_93OywRrynNLwMeta?.path ?? "/news/:postYear()/:postMonth()/:postDay()/:postSlug()",
    meta: _91postSlug_93OywRrynNLwMeta || {},
    alias: _91postSlug_93OywRrynNLwMeta?.alias || [],
    redirect: _91postSlug_93OywRrynNLwMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/[postYear]/[postMonth]/[postDay]/[postSlug].vue").then(m => m.default || m)
  },
  {
    name: indexmsvEq3m3QzMeta?.name ?? "news-all",
    path: indexmsvEq3m3QzMeta?.path ?? "/news/all",
    meta: indexmsvEq3m3QzMeta || {},
    alias: indexmsvEq3m3QzMeta?.alias || [],
    redirect: indexmsvEq3m3QzMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/all/index.vue").then(m => m.default || m)
  },
  {
    name: _91author_93BwbisqKRTmMeta?.name ?? "news-author-author",
    path: _91author_93BwbisqKRTmMeta?.path ?? "/news/author/:author()",
    meta: _91author_93BwbisqKRTmMeta || {},
    alias: _91author_93BwbisqKRTmMeta?.alias || [],
    redirect: _91author_93BwbisqKRTmMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/author/[author].vue").then(m => m.default || m)
  },
  {
    name: _91category_934YE3F1YCDwMeta?.name ?? "news-category-category",
    path: _91category_934YE3F1YCDwMeta?.path ?? "/news/category/:category()",
    meta: _91category_934YE3F1YCDwMeta || {},
    alias: _91category_934YE3F1YCDwMeta?.alias || [],
    redirect: _91category_934YE3F1YCDwMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/category/[category].vue").then(m => m.default || m)
  },
  {
    name: indexwniufHFDZqMeta?.name ?? "news",
    path: indexwniufHFDZqMeta?.path ?? "/news",
    meta: indexwniufHFDZqMeta || {},
    alias: indexwniufHFDZqMeta?.alias || [],
    redirect: indexwniufHFDZqMeta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91tag_93wrvGBYunX5Meta?.name ?? "news-tag-tag",
    path: _91tag_93wrvGBYunX5Meta?.path ?? "/news/tag/:tag()",
    meta: _91tag_93wrvGBYunX5Meta || {},
    alias: _91tag_93wrvGBYunX5Meta?.alias || [],
    redirect: _91tag_93wrvGBYunX5Meta?.redirect || undefined,
    component: () => import("/usr/src/science-news/pages/news/tag/[tag].vue").then(m => m.default || m)
  }
]