import { RouteLocationNormalized } from "~/.nuxt/vue-router";

function handleNonLowercasePaths(
  to: RouteLocationNormalized
): ReturnType<typeof navigateTo> {
  if (to.path.toLowerCase() !== to.path) {
    return navigateTo({
      path: to.path.toLowerCase(),
      query: to.query
    });
  }
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) return;

  return handleNonLowercasePaths(to);
});
